.portfolio-page {
    position: relative;
    padding-bottom: 39px;
    width: 100vw;
    min-height: 100vh;
    color: white;
    background-color: #181818;

    h1 {
        margin: 0;
    }
    
    .first-section {
        margin: 0 40px;
        position: relative;
        padding: 70px 40px 0;
        width: calc(100% - 95px);
        height: 70vh;
        min-height: 517px;
        // background-color: #64c571;
        color: #64c571;
        border-radius: 40px;
        overflow: hidden;

        h1 {
            font-size: 7em;
        }

        .wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            .block-poly {
                font-size: 25px;
                h2 {
                    font-size: 32px;
                }
            }
        }

        .in-row {
            margin: 0 auto;
            width: 95%;
        }
    }

    .work-section {
        position: relative;
        padding: 40px 40px 0;
        width: 100vw;
        background-color: #292929;
        color: #EBECDB;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        overflow: hidden;

        h1 {
            font-size: 3.5em;
        }
    }

    .tagListWrapper {
        padding: 0 20px;
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        .tagList {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;
            margin-bottom: 15px;
            font-size: 15px;
            justify-content: center;
        
            .tag {
                margin-right: 10px;
                margin-bottom: 10px;
                padding: 5px 10px;
                background-color: rgba(255, 255, 255, .1);
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(255, 255, 255, .3);
                }

                &:active,
                &.active {
                    background-color: rgba(255, 255, 255, 1);
                    color: black
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .portfolio-page {
        .first-section {
            h1 {
                font-size: 4.5em;
            }
            .wrapper {
                .block-poly {
                    font-size: 20px;
                    h2 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .portfolio-page {
        .first-section {
            h1 {
                font-size: 3.5em;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .portfolio-page {
        .first-section {
            margin: 0 20px;
            width: 100%;
            height: 400px;

            h1 {
                font-size: 2.5em;
            }
        }

        .tagListWrapper {
            display: none;
        }
    }
}

@media screen and (max-width: 560px) {
    .portfolio-page {
        .first-section {
            h1 {
                font-size: 2em;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .portfolio-page {
        .first-section {
            margin: 0 20px;
            width: 100%;
            height: 300px;
            min-height: 300px;
            padding: 0;
            padding-top: 40px;

            .understandCSS {
                display: none;
            }

            h1 {
                font-size: 2.5em;
            }

            .wrapper {
                .block-poly {
                    font-size: 20px;
                    h2 {
                        font-size: 32px;
                    }
                }
            }
        }
    }

    .portfolio-page .work-section {
        padding: 20px 5px 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;

        h1 {
            font-size: 2.5em;
        }
    }
}