.container {
    margin: 20px auto;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(360px,1fr));
    // grid-gap: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.container .item {
    display: inline-block;
    margin: 20px;
    max-width: calc(100% - 40px);
    width: 360px;
    text-align: left;
}

.container .item {
    cursor: pointer;
    
    .imgContain {
        position: relative;
        margin-bottom: 5px;
        width: 100%;
        height: 250px;
        background-color: #313131;
        border-radius: 20px;
        transform: scale(1);
        overflow: hidden;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
        transition-duration: .5s;

        .subImgBlur {
            background-size: 200%;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            filter: blur(20px);
            opacity: .65;
            transition-duration: 2s;
        }

        .subGradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
        }

        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // height: 100%;
            top: 0;
            left: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(-15deg) translate(20%, 30%) scale(1.3);
            box-shadow: 0 0 30px 3px rgba(0, 0, 0, .5);
            border-radius: 3px;
            z-index: 1;
            transition-duration: .5s;
        }
    }

    .contentInfo {
        transform: translateY(0);
        transition-duration: .5s;
    }

    &:hover {
        .imgContain {
            border-radius: 10px;
            transform: scale(1.2);
            box-shadow: 0 0 20px 2px rgba(0, 0, 0, .75);

            .subImgBlur {
                background-size: 300%;
            }
        }
        .img {
            top: -5%;
            left: -5%;
            transform: rotate(-5deg) translate(15%, 25%) scale(1.1);
            transform-origin: center;
        }

        .contentInfo {
            transform: translateY(20px);
        }
    }
}

.container .item .title {
    font-weight: bold;
    font-size: 19px;
}

.tagList {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
    margin-bottom: 15px;
    font-size: 15px;
    pointer-events: none;

    .tag {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 5px 10px;
        background-color: rgba(255, 255, 255, .1);
        border-radius: 5px;
    }
}

.content {
    min-width: 600px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    min-height: 100vh;
    max-height: 100vh;
    color: white;
    overflow: auto;

    .logoArea {
        position: relative;
        margin-bottom: 25px;
        width: 100%;
        height: 140px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .5);

        .subGradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
        }
        
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 0 30px 3px rgba(0, 0, 0, .5);
            border-radius: 3px;
            z-index: 1;
            transform-origin: center;
            transform: rotate(-10deg) translate(3%, -20%) scale(0.8);
        }


    }

    .describe {
        margin: 15px 0;
        font-size: 15px;
        color: #8d8d8d;
        white-space: break-spaces;
    }
    
    .btnViewProject {
        margin-top: 10px;
        padding: 10px 15px;
        width: 100%;
        font-family: inherit;
        font-size: 18px;
        white-space: nowrap;
        color: white;
        background-color: rgba(255, 255, 255, .1);
        border: unset;
        border-radius: 5px;
        outline: 0;
        cursor: pointer;
        transition-duration: .15s;

        &:hover {
            background-color: rgba(255, 255, 255, .3);
        }

        &:active {
            background-color: rgba(255, 255, 255, .4);
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    text-align: center;

    .rightSide {
        position: relative;
        width: 80%;
        min-height: 100vh;
        max-height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        flex-direction: column;
        overflow: auto;

        .padding {
            padding-top: 60px;
            margin: auto 30px;
        }
    }

    .youtubeIframe {
        width: 100%;
        height: 600px;
        border-radius: 20px;
        overflow: hidden;
    }

    .gallery {
        padding: 60px 30px 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
        grid-gap: 15px;
    
        .galleryItem {
            width: 100%;
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 20px;
            overflow: hidden;
            transition-duration: .25s;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0);
            cursor: pointer;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            &:hover {
                transform: scale(1.075);
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                border-radius: 10px;
                z-index: 1;
            }
        }
    }
}


.modal .content {
    padding: 40px 40px;
    width: 40%;
    text-align: left;

    h2 {
        font-size: 3rem;
    }

    p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1000px) {

    .container {
        margin: 0;

        .item {
            margin: 0 10px;
        }
    }

    .subImgBlur {
        display: none;
    }

    .modal {
        display: block;
        padding: 0 0;
        height: 100vh;
        overflow: auto;

        .content {
            padding: 60px 20px 20px;
            width: 100%;
            min-width: unset;
            min-height: unset;
            max-height: unset;
            overflow: unset;

            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 1.1rem;
            }
        }

        .rightSide {
            display: block;
            padding: 20px;
            width: 100%;
            height: auto;
            min-height: unset;
            max-height: unset;

            .padding {
                margin: 0;
            }
        }

        .youtubeIframe {
            height: 250px;

            iframe {
                height: 100% !important;
            }
        }

        .gallery {
            padding: 0;
        }
    }
}