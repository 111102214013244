.music-page {
    padding: 80px 20px 150px;
    width: 100vw;
    min-height: 100vh;
    background-image: radial-gradient(transparent 1.5px, rgb(15, 17, 21) 3px);
    background-size: 4px 4px;

    .background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #181818;
        overflow: hidden;

        .imgBackdrop {
            position: relative;
            width: 100%;
            height: 100%;
            transform: scale(2);
            background-size: cover;
            background-position: center;
            background-attachment: fixed;
            background-repeat: no-repeat;
            transition-duration: .75s;
            // z-index: 1;
        }
        
        // &::after {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     backdrop-filter: blur(30px) saturate(30%);
        // }
    }
    
    h1 {
        color: white;
    }

    p {
        margin: 5px 0;
        color: rgba(255, 255, 255, .5);
    }

    .music-secion {
        position: relative;
        margin: auto;
        max-width: 960px;
        z-index: 1;
    }
}

.music-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .music-item {
        position: relative;
        margin: 20px 10px;
        width: 300px;
        height: 300px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #ccc;
        background-size: cover;
        background-position: center;
        transition-duration: .25s;
        cursor: pointer;

        .music-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            opacity: .25;
            transition-duration: .25s;
        }

        .iframe-notice {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            top: 10px;
            padding: 10px;
            opacity: 0;
            color: white;
            white-space: nowrap;
            background-color: rgb(58, 58, 58, .5);
            border-radius: 20px;
            backdrop-filter: blur(5px) saturate(30%);
            transition-duration: .25s;
            pointer-events: none;

            p {
                margin: 0;
                font-size: 14px;
                text-align: center;
                white-space: nowrap;
                color: white;
                
                svg {
                    color: red;
                }
            }
        }

        &:hover {
            transform: scale(1.05);

            .music-overlay {
                opacity: 0;
            }

            .iframe-notice {
                opacity: 1;
            }
        }

        &:active {
            transform: scale(1);
            transition-duration: .1s;
        }

        .music-banner {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            padding: 0 10px;
            width: 95%;
            height: 80px;
            background-color: rgb(58, 58, 58, .5);
            border-radius: 20px;
            backdrop-filter: blur(5px) saturate(30%);

            .music-info {
                margin: auto;
                padding: 5px 10px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                color: white;
                overflow: hidden;

                .music-title {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    width: 100%;
                    font-size: 20px;
                    white-space: nowrap;
                    color: white;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    span {
                        display: block;
                        padding: 0;
                        width: fit-content;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        border-radius: 10px;
                        overflow: hidden;
                        transition-duration: .15s;
                    }

                    .iconNewTab {
                        display: inline-block;
                        opacity: 0;
                        transform: translateX(-5px);
                        transition-duration: .15s;
                        font-size: 14px;
                    }

                    &:hover {
                        // text-decoration: underline;

                        span {
                            padding: 0 5px;
                            background-color: rgba(255, 255, 255, .15);
                        }
                    }
                }

                .music-author {
                    margin: 0;
                    font-size: 12px;
                    color: white;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .music-play-button {
                margin: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                // background-color: rgba(255, 255, 255, 0.5);
                display: flex;
                align-items: center;

                .button-control {
                    margin: 0;
                    min-width: 40px;
                    min-height: 40px;
                }
            }
        }

        &:hover {
            .music-banner .music-info .music-title .iconNewTab {
                opacity: 1;
                transform: translateX(0px);
                margin-left: 5px;
            }
        }
    }
}

.player-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    transform: scale(1);
    z-index: 0;

    .youtubeContainer {
        width: 100%;
        height: 100%;
    }
}

.floating-status {
    position: absolute;
    top: -40px;
    margin: 5px auto;
    padding: 5px 10px;
    width: fit-content;
    color: white;
    background-color: #1818189d;
    backdrop-filter: blur(30px) saturate(30%);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .5);
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        background-color: #3838389d;
    }
}

.mini-player {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 110;

    .playerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #1818189d;
        backdrop-filter: blur(30px) saturate(30%);
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .5);

        .player-progress {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 5px;
            background-color: rgba(255, 255, 255, .25);
            z-index: 1;
            transition-duration: .25s;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            cursor: pointer;
            overflow: hidden;

            &:hover {
                background-color: rgba(255, 255, 255, .4);
            }

            .player-progress-bar {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: white;
            }
        }

        &:hover {
            .player-progress {
                height: 10px;
            }
        }

        .player-flex-tools {
            display: flex;
            padding: 10px;
            width: 100%;
            height: 100%;
            align-items: center;

            .player-thumbnail {
                position: relative;
                // border-radius: 10px;
                overflow: hidden;
                background-color: #ccc;
                background-size: cover;
                background-position: center;

                .link-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .5);
                    color: white;
                    opacity: 0;
                    display: flex;
                    font-size: 20px;
                    align-items: center;
                    justify-content: center;
                    transition-duration: .3s;
                    cursor: pointer;
                }

                &:hover {
                    .link-overlay {
                        transform: scale(1.2);
                    }
                }
            }

            .player-info-text {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                color: white;
                text-align: left;
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .song-name {
                    position: relative;
                    margin: 0;
                    height: 23px;

                    span {
                        position: absolute;
                        display: block;
                    }
                }

                .author-name {
                    margin: 0;
                    font-size: 15px;
                }

                p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .player-button {
                margin-left: auto;
                margin-right: 10px;
                display: flex;

                
            }
        }

        &:hover {
            .player-flex-tools {
                .player-thumbnail {
                    .link-overlay {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.button-control {
    display: flex;
    margin-left: 10px;
    padding: 10px;
    min-width: 40px !important;
    max-width: 40px !important;
    min-height: 40px;
    max-height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
    border: 1px solid rgba(255, 255, 255, .1);
    color: white;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, .2);
        border: 1px solid rgba(255, 255, 255, .2);
    }
}

@media screen and (max-width: 500px) {
    .mini-player {
        .playerContainer {
            .player-flex-tools {
                .player-thumbnail {
                    max-width: 40px !important;
                    max-height: 40px !important;
                    min-width: 40px !important;
                    min-height: 40px !important;
                    margin: 0 !important;
                }
            }

            .player-button {
                .button-control {
                    padding: 5px;
                    font-size: 15px;
                }
            }
        }
    }
}