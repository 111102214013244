.nameCard {
    margin: 0 auto;
    padding: 20px;
    width: 100vw;
    color: white;
    /* background-color: #19395594;
    border: 1px rgba(255, 255, 255, .1) solid; */
}

.nameCard .circlePic {
    position: relative;
    margin: 20px auto;
    padding: 5px;
    width: fit-content;
    height: fit-content;
    border: 3px #1d8cc1 solid;
    border-radius: 50%;
    overflow: hidden;
}
.nameCard .circlePic .img {
    width: 100%;
    height: 100%;
    width: 175px;
    height: 175px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    overflow: hidden;
}

.nameCard .identity {
    margin-top: 20px;
}

.nameCard .identity .name {
    margin-top: 5px;
    font-size: 25px;
}

.nameCard .identity .subName {
    color: rgb(184, 184, 184);
}

.nameCard .statusQuote {
    margin-top: 5px;
    font-size: 18px;
    color: rgb(219, 219, 219);
}

.nameCard .statusQuote span {
    font-size: 23px;
}

.nameCard .buttonPanel {
    margin: 8vh 0 20px 0;
}

.btn {
    margin: 5px 10px 10px 5px;
    padding: 8px 15px;
    min-width: 146px;
    font-family: inherit;
    font-size: 16px;
    white-space: nowrap;
    color: white;
    background-color: rgba(255, 255, 255, .2);
    border: unset;
    border-radius: 5px;
    outline: 0;
    transition-duration: .15s;
}

.btn:hover {
    background-color: rgba(255, 255, 255, .3);
}

.btn:active {
    background-color: rgba(255, 255, 255, .4);
    transition-duration: .1s;
}

.btn.facebook {
    background-color: #006AFF;
}

.btn.facebook:hover {
    background-color: #1576ff;
}

.btn.facebook:active {
    background-color: #005fe4;
}

.btn.github {
    color: #333;
    background-color: white;
}

.btn.github:hover {
    background-color: rgb(230, 230, 230);
}

.btn.github:active {
    background-color: rgb(197, 197, 197);
}

.btn:disabled {
    background-color: rgba(255, 255, 255, .1);
}

.btn > svg + span {
    margin-left: 5px;
}

@media only screen and (max-width: 500px) {

    .nameCard {
        // width: 100vw;
        background: transparent;
        backdrop-filter: unset;
        border: unset;
        box-shadow: unset;
    }

    .cardMobile {
        top: calc(50% - 43px);
    }
}