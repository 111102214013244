/* Definition font family */

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Anuphan Thin'), local('Anuphan-Thin'), url(../public/font/Anuphan-Thin.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Anuphan ExtraLight'), local('Anuphan-ExtraLight'), url(../public/font/Anuphan-ExtraLight.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Anuphan Light'), local('Anuphan-Light'), url(../public/font/Anuphan-Light.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Anuphan Regular'), local('Anuphan-Regular'), url(../public/font/Anuphan-Regular.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Anuphan Medium'), local('Anuphan-Medium'), url(../public/font/Anuphan-Medium.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Anuphan SemiBold'), local('Anuphan-SemiBold'), url(../public/font/Anuphan-SemiBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Anuphan Bold'), local('Anuphan-Bold'), url(../public/font/Anuphan-Bold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

:root {
    --primary-color: #1ba3e4;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    font-family: 'Anuphan', sans-serif;
    background-color: black;
    scroll-behavior: smooth;
    scrollbar-color: white transparent;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0
}

a{
    color: white;
    text-decoration: none;
    outline: 0;
}

img{
    pointer-events: none;
}

.loadingCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerContain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    z-index: 1;
    backdrop-filter: blur(30px) saturate(30%);

    &.mobile {
        backdrop-filter: none;
    }
}

.scrollDown {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    text-align: center;
    font-size: 20px;
    color: rgba(255, 255, 255, .5);
    z-index: 100;
}

.scrollDown a {
    text-decoration: none;
}

.commonBtn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-size: 20px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, .5);

    &:hover {
        background-color: rgba(255, 255, 255, .15);
        transform: scale(1.1);
    }

    &:active {
        background-color: rgba(255, 255, 255, .25);
        transform: scale(1);
    }
}

.credit {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 100vw;
    text-align: right;
    font-size: 15px;
    color: rgb(255 255 255 / 75%);
    text-align: center;
    background-color: rgba(0, 0, 0, .25);
}

.credit p {
    display: inline;
}
